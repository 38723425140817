@import "_common.css";
@import "_layout.css";
@import "_modules/_index.css";

:root {
    /* _common */
    --window-background-color: #eee;
    --link-text-color: #215dc6;
    --link-hover-text-color: #215dc6;
    --link-hover-background-color: #cde;
    --link-hover-text-decoration: underline;
    --h1-h2-background-color: #def;
    --h3-border-color: #def;
    --h4-border-color: #def;
    --h5-h6-background-color: #def;
    --h1-title-background-color: #def;
    --pre-background-color: #f0f8ff;
    --table-thead-tfoot-td-background-color: #d0d8e0;
    --table-thead-tfoot-th-background-color: #e0e8f0;
    --table-th-background-color: #eee;
    --table-th-border-color: #c0c0c0;
    --table-td-background-color: #eef5ff;
    --table-td-border-color: #c0c0c0;
    --table-conflict-thead-th-background-color: #ffffff;
    --table-conflict-thead-th-border-color: #c0c0c0;
    --new1-text-color: red;

    /* _modules */
    --plugin-ntbr-background-color-td: #eef5ff;
    --plugin-ntbr-background-color-th: #d0d8e0;

    --plugin-calendar-top-background-color: #eef5ff;
    --plugin-calendar-today-background-color: #ffffdd;
    --plugin-calendar-saturday-background-color: #dde5ff;
    --plugin-calendar-sunday-background-color: #fee;
    --plugin-calendar-blank-background-color: #eef5ff;
    --plugin-calendar-weekday-background-color: #eef5ff;
    --plugin-calendar-weeklabel-background-color: #dde5ee;
    --plugin-minicalendar-top-background-color: #fff5ee;
    --plugin-minicalendar-saturday-background-color: #dde5ff;
    --plugin-minicalendar-sunday-background-color: #fee;
    --plugin-minicalendar-blank-background-color: #eee;
    --plugin-minicalendar-weekday-background-color: #eef5ff;
    --plugin-minicalendar-weeklabel-background-color: #e0e0e0;

    --plugin-vote-label-background-color: #fcc;
    --plugin-vote-td1-background-color: #dde5ff;
    --plugin-vote-td2-background-color: #eef5ff;
}

a.rel-wiki-page:visited {
    color: #a63d21;
}

h3 {
    border-width: 1px 5px 3px 10px;
}

h4 {
    border-width: 0 0 0 18px;
}

.style_calendar td {
    border: 1px solid #ccd5dd;
}

blockquote {
    margin-top: .5em;
    margin-bottom: .5em;
}

.ministyle_calendar td{
    border: 1px solid #e0e0e0;
}

/*p.cads {*/
/*    background-color: #def;*/
/*}*/

/*.cads a {*/
/*    color: black;*/
/*    border-style: none;*/
/*}*/
